import React, { Component } from "react"
import MaterialTable from "material-table"
import PropTypes from "prop-types"
import { ConditionalScrollBar, Scrollbar } from "../../components/Scrollbar"
import "./style.css"
import { IconButton, Checkbox } from "@material-ui/core"
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowForward as ArrowForwardIcon,
  Search as SearchIcon,
  ArrowDropUpOutlined as ArrowDropUpOutlinedIcon,
  ArrowDropDownOutlined as ArrowDropDownOutlinedIcon,
  Save,
} from "@material-ui/icons"
import { momentDateToString, stringDateToFormattedDate } from "../../helpers/date"
import { containsInvariant, groupBy } from "../../helpers/helpers"

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRows: [],
      sortField: "",
      search: "",
      sortOrder: "DESC",
      data: this.props.data,
      filteredData: this.props.data,
      rowEditing: -1,
      itemEditing: {},
      groupsExpanded: [],
    }

    this.onChangeCurrRowEditing = this.onChangeCurrRowEditing.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      let filteredData = this.state.data
      if (this.props.groupBy) {
        // filteredData = groupBy(filteredData, this.props.groupBy)
        filteredData = filteredData
          .sort((a, b) => a[this.props.groupBy].localeCompare(b[this.props.groupBy]))
          .map((x) => ({ ...x, show: false }))
      }

      this.setState({ data: this.state.data, filteredData })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data }
    } else return null
  }

  onRowClick = (rowId) => {
    let indexFound = this.state.selectedRows.indexOf(rowId)
    let selectedRows = this.state.selectedRows
    if (indexFound >= 0) selectedRows.splice(indexFound)
    else selectedRows = [...selectedRows, rowId]

    if (this.props.handleSelection) this.props.handleSelection(selectedRows)
    if (this.props.handleRowClick) this.props.handleRowClick(rowId)
    this.setState({ selectedRows })
  }

  onHeaderClick = (field) => {
    let sortOrder
    if (this.state.sortField != field) {
      sortOrder = "DESC"
    } else {
      sortOrder = this.state.sortOrder === "ASC" ? "DESC" : "ASC"
    }
    const data = this.state.filteredData
    data.sort((a, b) => {
      if (isNaN(a[field]) || isNaN(b[field])) return ("" + a[field]).localeCompare("" + b[field])
      else {
        return parseFloat(a[field]) > parseFloat(b[field]) ? 1 : -1
      }
    })

    if (sortOrder === "DESC") data.reverse()

    this.setState({
      filteredData: data,
      sortField: field,
      sortOrder: sortOrder,
    })
  }

  onSearchValueChanged = (e) => {
    let search = e.target.value
    if (search != undefined) {
      search = search.toLowerCase()
      const data = this.state.data
      const filteredData = data.filter((item) => {
        var contains = false
        this.props.columns.forEach((column) => {
          const field = column.field
          if (containsInvariant(item[field], search)) {
            contains = true
            return
          }
        })
        if (contains) return item
      })

      this.setState({ filteredData: filteredData, search: search })
    } else {
      this.setState({ search: search })
    }
  }

  onEditableColumnValueChanged = (event, itemModified) => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    let newItem = {
      ...itemModified,
      [name]: value,
    }
    if (this.props.handleColumnEdit) {
      this.props.handleColumnEdit(newItem)
    }
  }

  onChangeCurrRowEditing = (e) => {
    const target = e.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({ itemEditing: { ...this.state.itemEditing, [name]: value } })
  }

  onDelete = () => {}

  render() {
    const {
      props: {
        title,
        columns,
        options = {},
        sort,
        data,
        groupBy,
        handleAdd,
        handleEdit,
        handleDelete,
        handleSelection,
        handleRowClick,
        handleColumnEdit,
        className = "",
        children,
        ...other
      },
      state: { selectedRows, rowEditing, itemEditing, groupsExpanded },
    } = this

    var filteredData = this.state.filteredData
    if (!filteredData || filteredData.length == 0) filteredData = []
    const hasHeader = options.search || title || handleAdd
    var lastGroup = "",
      isGroupExpanded = true

    return (
      <div
        className={`app-table-wrapper d-flex flex-column ${className} ${options.noPadding ? "nopadding" : "p-2"}`}
        {...other}
      >
        {hasHeader ? (
          <div className="app-table-header" style={{ ...options.headerStyle }}>
            {options.search ? (
              <div className="app-table-search">
                <input
                  value={this.state.search}
                  onChange={this.onSearchValueChanged}
                  type="search"
                  placeholder="Rechercher"
                />
                <SearchIcon className="m-auto" />
              </div>
            ) : null}
            {title ? (
              <div className="app-table-title">
                <h3>{title}</h3>
              </div>
            ) : null}
            {handleAdd ? (
              <div className="app-table-add">
                <button
                  className="app-table-button-icon"
                  onClick={() => {
                    if (options.inlineAddAndEdit) {
                      filteredData.unshift({})
                      this.setState({ filteredData: filteredData, rowEditing: 0 })
                    } else handleAdd()
                  }}
                  size="small"
                >
                  <AddIcon />
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        <ConditionalScrollBar show={options?.fixedHeight}>
          <table className="app-table">
            {children}
            <thead className="app-table-head">
              <tr className="app-table-row">
                {groupBy && <td></td>}
                {columns.length > 0 &&
                  columns.map((column) => {
                    return (
                      <td key={column.field} onClick={() => this.onHeaderClick(column.field)}>
                        {column.title}

                        <IconButton size="small">
                          {this.state.sortField === column.field ? (
                            this.state.sortOrder === "ASC" ? (
                              <ArrowDropDownOutlinedIcon />
                            ) : (
                              <ArrowDropUpOutlinedIcon />
                            )
                          ) : (
                            <ArrowDropDownOutlinedIcon />
                          )}
                        </IconButton>
                      </td>
                    )
                  })}
                {handleDelete ? <td></td> : null}
              </tr>
            </thead>
            <tbody className="app-table-body">
              {filteredData.map((item, i) => {
                let rowStyle = {}
                let isRowSelected = selectedRows.indexOf(item.id) >= 0
                if (options?.affectRowColorBasedOnColumn) {
                  rowStyle = { backgroundColor: `${item[options.affectRowColorBasedOnColumn]}` }
                } else if (options?.affectRowColor?.length > 0) {
                  options.affectRowColor.forEach((condition) => {
                    if (
                      (!condition.inversed && item[condition.column]) ||
                      (condition.inversed && !item[condition.column])
                    ) {
                      rowStyle = condition.style
                    }
                  })
                } else if (options?.selectedRowColor && isRowSelected) {
                  rowStyle = { backgroundColor: options.selectedRowColor }
                }

                let isNewGroup = false
                if (groupBy) {
                  isNewGroup = groupBy && lastGroup != item[groupBy]
                  if (isNewGroup) {
                    lastGroup = item[groupBy]
                    isGroupExpanded = !item[groupBy] || groupsExpanded.indexOf(i) >= 0
                  }
                }

                return (
                  <>
                    {isNewGroup && (
                      <tr
                        className="cursor-pointer"
                        onClick={() => {
                          if (groupsExpanded.indexOf(i) >= 0) {
                            groupsExpanded.splice(groupsExpanded.indexOf(i))
                            this.setState({ groupsExpanded })
                          } else {
                            this.setState({ groupsExpanded: [...groupsExpanded, i] })
                          }
                        }}
                      >
                        <td
                          style={{
                            backgroundColor: "var(--color-primary-shade)",
                            color: "#fff",
                            cursor: "pointer",
                            paddingLeft: "4px",
                          }}
                          colSpan={columns.length + 2}
                        >
                          {item[groupBy]}
                          <span className="ml-2">
                            <ArrowForwardIcon style={{ transform: isGroupExpanded ? "rotate(90deg)" : "" }} />
                          </span>
                        </td>
                      </tr>
                    )}

                    <tr
                      key={item.id}
                      className={`app-table-row ${
                        isRowSelected && (!options || !options?.noSelection) ? "active" : ""
                      }`}
                      onClick={() => this.onRowClick(item.id)}
                      style={{ ...rowStyle, visibility: isGroupExpanded ? "" : "collapse" }}
                    >
                      {groupBy && (
                        <td>
                          <Checkbox size="small" color="primary" checked={isRowSelected} />
                        </td>
                      )}
                      {columns.map((column) => {
                        var properties = Array.isArray(column.field) ? column.field : column.field.split(".")
                        var value =
                          rowEditing == i
                            ? properties.reduce((prev, curr) => prev && prev[curr], itemEditing)
                            : properties.reduce((prev, curr) => prev && prev[curr], item)

                        let onChangeEvent = null
                        if (column.editable) {
                          onChangeEvent = (event) => this.onEditableColumnValueChanged(event, item)
                        }
                        if (column.boolean) {
                          return (
                            <td key={column.field} style={{ width: "1%", whiteSpace: "nowrap" }}>
                              <Checkbox
                                color="primary"
                                name={column.field}
                                checked={value ? true : false}
                                onChange={onChangeEvent}
                                value={value}
                              />
                            </td>
                          )
                        } else {
                          return (
                            <td key={column.field}>
                              {column.editable || rowEditing == i ? (
                                <input
                                  name={column.field}
                                  type="text"
                                  onChange={(e) =>
                                    column.editable ? this.onChangeEvent(e) : this.onChangeCurrRowEditing(e)
                                  }
                                  value={column.dateFormat ? stringDateToFormattedDate(value) : value}
                                />
                              ) : (
                                <span>{column.dateFormat ? stringDateToFormattedDate(value) : value}</span>
                              )}
                            </td>
                          )
                        }
                      })}
                      {handleDelete || handleEdit ? (
                        <td style={{ width: "1%", whiteSpace: "nowrap" }}>
                          <div className="app-table-actions">
                            {handleEdit ? (
                              rowEditing == i ? (
                                <button
                                  className="app-table-button-icon"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (itemEditing.id) handleEdit(itemEditing)
                                    else handleAdd(itemEditing)
                                  }}
                                  size="small"
                                >
                                  <Save />
                                </button>
                              ) : options.inlineAddAndEdit ? (
                                <button
                                  className="app-table-button-icon"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({ rowEditing: i, itemEditing: item })
                                  }}
                                  size="small"
                                >
                                  <EditIcon />
                                </button>
                              ) : (
                                <button
                                  className="app-table-button-icon"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleEdit(item)
                                  }}
                                  size="small"
                                >
                                  <SearchIcon />
                                </button>
                              )
                            ) : null}
                            {handleDelete ? (
                              <button
                                className="app-table-button-icon"
                                color="danger"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleDelete(item)
                                }}
                                size="small"
                              >
                                <DeleteIcon />
                              </button>
                            ) : null}
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
        </ConditionalScrollBar>
      </div>
    )
  }
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  options: PropTypes.object.isRequired,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleRowSelected: PropTypes.func,
  handleColumnEdit: PropTypes.func,
}

export default Table
